<template>
    <div>
        <v-dialog
          :value="dialog"
          @input="dialog = $event"
          max-width="300px"
        >   
          <v-card>
            <v-card-title>
              <div class="d-flex justify-space-between" style="width: 100%;">
                <span>콘텐츠 저장</span>
                <v-spacer></v-spacer>
                <v-btn  icon @click="dialog = false">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
              
            
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-checkbox label="나중에 볼 콘텐츠"  value= "나중에 볼 콘텐츠" hide-details  ></v-checkbox>
              <v-checkbox label="매거진"  value="매거진" hide-details></v-checkbox>

    
            </v-card-text>
            <v-card-actions>
              <v-btn
            text
            @click="dialog2 = true"
            class="mx-auto"
          >
            + 새 저장목록 만들기
          </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog2"
            max-width="300px"
            >
        <v-card>
          <v-card-title>
          </v-card-title>

          <v-card-text>
            <v-text-field
              name="name"
              label="이름"
              id="id"
              placeholder="감상목록의 제목을 입력하세요"
              size="150"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-select
              :items="['공개','비공개']"
              v-model="value"
              label="공개 범위 설정"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog2 = false"
            >
              만들기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
    
<script>
export default {
    data() {
      return {
        dialog2: false,
        items: [
          { title: 'Item 1' },
          { title: 'Item 2' },
          { title: 'Item 3' },
        ],
      };
    },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>