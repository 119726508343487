<!-- 7 -->
<template>
  <div id="watch">
    <v-container fluid>
      <v-row>
        <v-col cols="11" class="mx-auto">
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-skeleton-loader
                type="card-avatar, article, actions"
                :loading="videoLoading"
                tile
                large
              >
                <!-- <v-responsive height="100%" :aspect-ratio="16/9"> -->
                  <template>
                    <div                
                      @mouseenter="showControls"
                      @mouseleave="hideControls"
                    >

                    <v-carousel v-model="model"
                      :show-arrows="isHovering"
                      :hide-delimiter-background="!isHovering"
                      :hide-delimiters="!isHovering"
                    >
      
                      <v-carousel-item
                        v-for="(page, i) in getPages()"
                        :key="i"
                      >

                      <v-btn
                        @click="gotoWatchLink()"
                        text
                        block
                        tile
                        height="100%"
                        width="100%"
                        class="pa-0 d-flex "
                      >
                        <v-sheet
                          tile
                          height=100%
                          width=100%
                        >
                          <!-- <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          > -->
                          <v-img
                          :src="page" :alt="'brave '+i"
                          
                          height=100%
                          width=100%
                          contain
                          ></v-img>
                          <!-- </v-row> -->
                        </v-sheet>
                      </v-btn>
                      </v-carousel-item>
                    </v-carousel>
                    </div>
                  </template>
                <!-- </v-responsive> -->
                <v-card flat tile class="card">
                  <v-card-title class="pl-0 pb-0">{{
                    video.title
                  }}</v-card-title>
                  <div class="d-flex flex-wrap justify-space-between" id="btns">
                    <v-card-subtitle
                      class="pl-0 pt-0 pb-0 subtitle-1"
                      style="line-height: 2.4em;"
                    >
                      조회수 {{ video.views }} <v-icon>mdi-circle-small</v-icon
                      >{{ video.createdAt }}
                      
                    </v-card-subtitle>
                    <v-card-actions class="pt-0 pl-0 grey--text">
                      <v-btn text
                        ><v-icon class="pr-2">mdi-thumb-up</v-icon> 1.5k</v-btn
                      >
                      <v-btn text
                        ><v-icon class="pr-2">mdi-thumb-down</v-icon>
                        1.5k</v-btn
                      >
                      <v-btn text  @click.stop="sdialog = true"><v-icon>mdi-share</v-icon> 공유</v-btn>
                      <v-btn text @click.stop="tdialog = true"><v-icon>mdi-translate</v-icon> 번역</v-btn>

                      <v-btn text @click.stop="pldialog = true">
                        <v-icon>mdi-playlist-plus</v-icon> 저장</v-btn
                      >
                      <v-btn text  @click.stop="rdialog = true"><v-icon>mdi-flag</v-icon> 신고</v-btn>
                      

                      <social-dialog v-model="sdialog"></social-dialog>

                      
                      <translate-dialog v-model="tdialog"></translate-dialog>
                      <report-dialog v-model="rdialog"></report-dialog>
                      <save-play-list-dialog v-model="pldialog"></save-play-list-dialog>
                      <donate-dialog v-model="ddialog"></donate-dialog>

                    </v-card-actions>
                  </div>
                </v-card>

                <v-row class="justify-space-between">
                  <v-col cols="6" sm="6" md="5" lg="5">
                    <v-card class="transparent" flat>
                      <v-list-item three-line>
                        <v-list-item-avatar size="50" color="grey lighten-3"
                        @click="$router.push('/channels/1 ')"
                          ><v-img
                          :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                        <v-list-item-content class="align-self-auto">
                          <v-list-item-title class="font-weight-medium mb-1"
                            >나무</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >{{ video.subscribers }} 구독자
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="6" sm="6" md="4" lg="4">
                    <div class="d-flex justify-end align-center">
                      <!-- red -> 5c2e91 -->
                      <v-btn class="white--text mt-6" tile large depressed
                color="#5c2e91"
                        >구독</v-btn
                      >
                      <!-- <v-btn icon class="ml-5 mt-6"
                        ><v-icon>mdi-bell</v-icon></v-btn
                      > -->
                      <v-btn icon class="ml-3 mt-6"  @click.stop="ddialog = true"><v-icon>mdi-copyright</v-icon></v-btn>
                      <v-btn icon class="ml-3 mt-6"  router 
                      :to="'/studio/translation/edit' + (
                      (this.$route.query.content === 'magazine' ||
                        this.$route.query.content === 'novel') ? '?type=file' : '')">
                        <v-icon>mdi-handshake-outline</v-icon></v-btn>
                      
                    </div>
                  </v-col>
                  <v-col class="pl-11" offset="1" cols="11" md="11">
                    <p>
                      {{
                        truncate
                          ? truncateText(video.description, 150)
                          : video.description
                      }}
                    </p>
                    <v-btn text @click="show" class="remove-hover-bg"
                      >더보기</v-btn
                    >
                  </v-col>
                  <v-col>
                    <p class="mb-0">148 답글</p>
                    <input type="text" ref="hello" />
                    <v-card class="transparent" flat>
                      <v-list-item three-line class="pl-0">
                        <v-list-item-avatar size="50" color="grey lighten-3"
                          ><v-img
                          :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                        <v-list-item-content class="align-self-auto">
                          <v-text-field
                            v-model="comment"
                            placeholder="댓글 쓰기..."
                            @click="showCommentBtns = true"
                          >
                          </v-text-field>
                          <div
                            v-if="showCommentBtns"
                            class="d-inline-block text-right"
                          >
                            <v-btn
                              text
                              @click="showCommentBtns = !showCommentBtns"
                              >취소</v-btn
                            >
                            <v-btn
                              class="blue darken-3 white--text"
                              depressed
                              tile
                              :disabled="comment === ''"
                              >확인</v-btn
                            >
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>

                    <v-card class="transparent" flat v-for="i in 5" :key="i">
                      <v-list-item three-line class="pl-0 mt-2">
                        <v-list-item-avatar size="50" color="grey lighten-3"
                          ><v-img
                          :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-medium caption mb-1"
                            >나무
                            <span class="font-weight-light grey--text">
                              1 일 전</span
                            ></v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text--darken-4 caption"
                            >Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Tempore deleniti aspernatur nostrum eius
                            dignissimos repellendus. Fugiat, aspernatur deserunt
                            iusto natus consectetur voluptatem voluptate
                            laboriosam pariatur qui animi repudiandae quae
                            dolorem.</v-list-item-subtitle
                          >
                          <!-- <v-list-item-action> -->
                          <div>
                            <!-- <button
                              class="caption font-weight-bold d-inline-block pa-2 grey--text text--darken-3"
                              style="cursor: pointer; outline: none"
                              @click.stop.prevent="showReply"
                            >
                              REPLY
                            </button> -->
                            <v-btn
                              text
                              small
                              :ripple="false"
                              @click.stop="showReply(`${'reply' + i}`)"
                              >답글</v-btn
                            >
                          </div>
                          <div class="d-none" :ref="`${'reply' + i}`">
                            <v-list-item three-line class="pl-0">
                        <v-list-item-avatar size="40" color="grey lighten-3"
                          ><v-img
                          :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                              <v-list-item-content
                                class="align-self-auto mt-0 pt-0"
                              >
                                <v-form :ref="`form${i}`">
                                  <v-text-field
                                    :ref="`${'input' + i}`"
                                    class="pt-0 mt-0 body-2"
                                    placeholder="답글 쓰기..."
                                    :value="repliesInput[`input${i}`]"
                                  >
                                  </v-text-field>
                                </v-form>
                                <div
                                  :ref="i + 'btns'"
                                  class="d-inline-block text-right"
                                >
                                  <v-btn text @click="hideReply(i)" small
                                    >취소</v-btn
                                  >
                                  <v-btn
                                    class="blue darken-3 white--text"
                                    depressed
                                    tile
                                    small
                                    @click="addReply(i)"
                                    >확인</v-btn
                                  >
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- </v-list-item-action> -->
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-skeleton-loader>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <div v-for="i in 10" :key="i" class="mb-5">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-three-line"
                  :loading="loading"
                  tile
                  large
                >
                  <v-card class="card" tile flat>
                    <v-row no-gutters>
                      <v-col class="mx-auto" cols="3" sm="3" md="5" lg="5">
                        <!-- <v-responsive max-height="100%"> -->
                        <v-img
                          class="align-center"
                          :src="require(`@/assets/thumbnails/thumbnail_${String(i).padStart(2,'0')}.jpg`)"
                          height="100"
                          contain
                        >
                        </v-img>
                        <!-- </v-responsive> -->
                      </v-col>
                      <v-col>
                        <div class="ml-2 d-flex flex-column justify-space-around" style="height:100%">
                          <v-card-title
                            class="pl-2 pt-0 subtitle-1 font-weight-bold"
                            style="line-height: 1"
                          >
                            콘텐츠 이름
                          </v-card-title>

                          <v-card-subtitle
                            class="pl-2 pt-2 pb-0 text-caption"
                            style="line-height: 1"
                            
                          >
                            채널<br />
                            조회수 11만<v-icon>mdi-circle-small</v-icon>6 시간 전
                          </v-card-subtitle>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SocialDialog from '@/components/dialogs/SocialDialog.vue';
import TranslateDialog from '@/components/dialogs/TranslateDialog.vue';
import ReportDialog from '@/components/dialogs/ReportDialog.vue';
import SavePlayListDialog from '@/components/dialogs/SavePlayListDialog.vue';
import DonateDialog from '@/components/dialogs/DonateDialog.vue';

export default {
  data: () => ({
    loading: true,
    videoLoading: true,
    video: [],
    truncate: true,
    comment: '',
    showCommentBtns: false,
    repliesInput: {},


    isHovering:false,
      model: 0,
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ],
      pages:[
        require('@/assets/brave/brave_1.jpg'),
        require('@/assets/brave/brave_2.jpg'),
        require('@/assets/brave/brave_3.jpg'),
        require('@/assets/brave/brave_4.jpg'),
        require('@/assets/brave/brave_5.jpg'),
      ],

      
      sdialog: false,
      tdialog: false,
      rdialog: false,
      pldialog: false,
      ddialog: false,      
  }),

  methods: {
    getPages(){
      
      if(this.$route.query.content === 'novel'){
        return [require('@/assets/thumbnails/cnp_thumb.png')]
      } else if(this.$route.query.content === 'magazine' ||
      this.$route.query.content === 'news'){
        return [require('@/assets/thumbnails/bnb_thumb.png')]
      } else{
        return this.pages
      }
    },
    gotoWatchLink(){
      switch (this.$route.query.content) {
        case 'novel':
          this.$router.push({name: 'Novel'});
          break;
        case 'magazine':
          this.$router.push({name: 'Magazine'});
          break;
        case 'news':
          this.$router.push({name: 'Magazine'});
          break;
        default:
          this.$router.push({name: 'Comics'});
          break;
      }
    },
    getVideos() {
      this.video = {
        channelName: '나무',
        subscribers: '100k',
        createdAt: '6 시간 전',
        views: '200,459',
        videoUrl: '/video.mp4',
        title: '용기',
        description:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus quibusdam incidunt consequuntur, nostrum aperiam, natus quidem qui corrupti reprehenderit quaerat neque voluptatibus? Ullam, maiores temporibus!'
      }
    },
    showReply(id) {
      this.$refs[id][0].classList.toggle('d-none')
    },
    hideReply(id) {
      this.$refs[`form${id}`][0].reset()
      this.$refs['reply' + id][0].classList.toggle('d-none')
    },
    addReply(id) {
      this.$refs[`form${id}`][0].reset()
      console.log(this.$refs[`input${id}`][0].$refs.input.value)
    },
    show(event) {
      if (event.target.innerText === '더보기') {
        this.truncate = false
        event.target.innerText = '줄이기'
      } else {
        this.truncate = true
        event.target.innerText = '더보기'
      }
    },
    truncateText(string = '', num) {
      if (string.length <= num) {
        return string
      }
      return string.slice(0, num)
    },
    
    showControls() {
      this.isHovering = true
    },
    hideControls() {
      this.isHovering = false
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.videoLoading = false
      this.getVideos()
    }, 1000)
  },
  
  components: {
    SocialDialog,
    TranslateDialog,
    ReportDialog,
    SavePlayListDialog,
    DonateDialog,
  },
}
</script>

<style lang="scss">
video {
  max-width: 100%;
  /* min-height: 360px; */
  /* width: 640px;
  height: 360px; */
}

#btns {
  border-bottom: 1px solid #e0d8d8;
  button {
    color: #7f7f7f;
  }
}

button.v-btn.remove-hover-bg {
  background-color: initial !important;
  &:hover {
    // background-color: #f9f9f9;
  }
}

.v-btn__content{
  width: 100%;
  height: 100%;
}
</style>
