<!-- 7-C -->
<template>
    <div>
        <v-dialog
          :value="dialog"
          @input="dialog = $event"
          max-width="300px"
        >   
          <v-card>
            <v-card-title class="text-body-1 justify-center">2코인 후원</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-6">
                <!-- TODO: parameterize -->
                <p class="text-center">원피스 1270화를 소장합니다.</p>
                <p class="text-center">소장기간 7일</p>
    
            </v-card-text>
            <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog2 = true; dialog = false"
          >
            확인
          </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog2"
            max-width="300px"
            >
        <v-card>
          <v-card-title>
            <!-- <span>Dialog </span>
            <v-spacer></v-spacer> -->
          </v-card-title>
          <v-card-text>
            <p class="text-center">
              <span>2코인 후원이 완료되었습니다.</span>
            </p>
            <p class ="text-center yellow--text text--accent-4">감사합니다</p>
          </v-card-text>

            <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog2 = false"
          >
            확인
          </v-btn>
           
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
    
<script>
export default {
    data() {
      return {
        dialog2: false,
      };
    },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>