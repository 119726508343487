<template>

<v-card flat> 
    <v-container >
        
    <v-card-title >
        <p class="my-0" >Grape에 공유하는 정보</p>
        <p class="my-0 text-caption">내 구독 정보를 볼 수 있는 사용자를 선택하세요.</p>
        <p class="my-0 text-caption"><a>Grape</a> 서비스 약관 및 <a>개인정보처리방침</a>을 살펴보세요.</p>
    </v-card-title>
    <v-card-text>
        <v-divider></v-divider>
        <v-container >
            <v-row>
                <v-col cols="2">
                    구독
                </v-col>
                <v-col cols="10">
                    <v-switch label="이 브라우저에서 알림 받기" class="multipleLineLabel" >
                        <template v-slot:label>
                            <p class="mb-0">내 구독정보 모두 비공개</p>
                            <p class="mb-0 text-caption">구독정보가 공개되게 하는 기능을 사용하지 않는 한 내 구독정보가 다른 사용자에게 표시되지 않습니다. 구독정보가 표시되게 만들 수 있는 기능이 무엇인지 <a>자세히 알아보거나</a> <a>여기</a>서 구독정보를 관리하세요</p>
                        </template>
                    </v-switch>

                </v-col>
            </v-row>
            
        </v-container>
    </v-card-text>
</v-container>

  </v-card>
</template>

<script>
export default {
    data() {
        return {
            comment:'on',
            switches:[
                {
                    label:'구독',
                    desc:'구독중인 채널 활동에 대한 알람 수신',
                    value:'true',
                 },
                {
                    label:'맞춤 콘텐츠',
                    desc:'감상한 콘텐츠를 바탕으로 내가 좋아할 만한 콘텐츠에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'내 채널의 활동',
                    desc:'내 채널이나 콘텐츠의 댓글 및 기타 활동에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'내 댓글에 달린 답글',
                    desc:'다른 채널에 남긴 댓글 및 게시물의 활동에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'멘션',
                    desc:'다른 사용자가 내 채널을 언급하면 알림 받기',
                    value:'true',
                },
                {
                    label:'공유된 콘텐츠',
                    desc:'다른 사용자가 자신의 채널에서 내 콘텐츠를 공유하면 알림 수신',
                    value:'true',
                },
                {
                    label:'프로모션 콘텐츠 및 혜택',
                    desc:'프로모션 콘텐츠 및 회원 전용 혜택과 같은 혜택에 대한 알림 수신',
                    value:'true',
                },

            ],
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color: #065fd4;
}

.v-card__title{
    flex-direction: column;
    align-items: flex-start;
}
</style>

<style>

.multipleLineLabel>.v-input__control>.v-input__slot>.v-label{
    flex-direction: column;
    align-items: flex-start;
}
</style>