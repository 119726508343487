<template>

<v-card flat> 
    <v-container>
        
    <v-card-title >
        <p class="my-0" >알림 받을 시간과 방법을 선택하세요</p>
        <p class="text-caption">채널은 Grape에 공개되는 나의 공간입니다. 콘텐츠를 업로드하고,콘텐츠에 댓글을 달고, 감상목록을 만들려면 채널이 필요합니다.</p>
    </v-card-title>
    <v-card-text>
        <p class="my-0 mt-5"> 일반</p>
        <p class="text-caption">모바일 및 데스크톱 알림 관리.</p>
        <v-divider></v-divider>
        <v-container >
            <v-row>
                <v-col cols="2">
                    데스크탑 알림 표시
                </v-col>
                <v-col cols="10">
                    <v-switch label="이 브라우저에서 알림 받기" class="multipleLineLabel" >
                        <template v-slot:label>
                            <p class="mb-0">이 브라우저에서 알림 받기</p>
                            <p class="mb-0 text-caption"><v-avatar
                                size="25"
                            >
                                <img :src="require('@/assets/Google_Chrome_icon_(February_2022).svg')" alt="alt">
                            </v-avatar> Chrome</p>
                            <p class="mb-0 text-caption">Grape 감상 중이 아니어도 내 컴퓨터에서 알림 수신</p>
                        </template>
                    </v-switch>

                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    내 환경설정
                </v-col>
                <v-col cols="10">
                    <v-switch v-for="(item,i) in switches" v-model=item.value :label="item.label" :value="true" :key="i" class="multipleLineLabel">
                        <template v-slot:label>
                            <p class="mb-0">{{item.label}}</p>
                            <p class="mb-0 text-caption">{{item.desc}}</p>
                        </template> 
                    </v-switch>

                </v-col>
            </v-row>
            
        </v-container>
    </v-card-text>
</v-container>

  </v-card>
</template>

<script>
export default {
    data() {
        return {
            comment:'on',
            switches:[
                {
                    label:'구독',
                    desc:'구독중인 채널 활동에 대한 알람 수신',
                    value:'true',
                 },
                {
                    label:'맞춤 콘텐츠',
                    desc:'감상한 콘텐츠를 바탕으로 내가 좋아할 만한 콘텐츠에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'내 채널의 활동',
                    desc:'내 채널이나 콘텐츠의 댓글 및 기타 활동에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'내 댓글에 달린 답글',
                    desc:'다른 채널에 남긴 댓글 및 게시물의 활동에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'멘션',
                    desc:'다른 사용자가 내 채널을 언급하면 알림 받기',
                    value:'true',
                },
                {
                    label:'공유된 콘텐츠',
                    desc:'다른 사용자가 자신의 채널에서 내 콘텐츠를 공유하면 알림 수신',
                    value:'true',
                },
                {
                    label:'프로모션 콘텐츠 및 혜택',
                    desc:'프로모션 콘텐츠 및 회원 전용 혜택과 같은 혜택에 대한 알림 수신',
                    value:'true',
                },

            ],
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color: #065fd4;
}

.v-card__title{
    flex-direction: column;
    align-items: flex-start;
}
</style>

<style>

.multipleLineLabel>.v-input__control>.v-input__slot>.v-label{
    flex-direction: column;
    align-items: flex-start;
}
</style>