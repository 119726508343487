<!-- 19 -->
<template>

    <v-card class="transparent" flat>
        <v-card-title>
                <h3>신고기록
                </h3>
    
        </v-card-title>
        
        <v-card-text>
            
        <v-select
                    :items="['가나다순','최신순']"
                    label=""
                    style="width: 100px"
                    value='가나다순'
                  ></v-select>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="(item, i) in dummies.slice(0,5)"
            :key="i"
            class="mx-xs-auto"
          >
            <v-skeleton-loader type="card-avatar" :loading="loading">
              <video-card
                :card="{ maxWidth: 350 }"
                :video="item.video"
                :channel="item.channel"
              ></video-card>
              <!-- <v-card
                class="content-bg card mx-auto"
                max-width="350"
                flat
                tile
              >
                <v-img
                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="200px"
                ></v-img>
                <v-row no-gutters>
                  <v-col cols="2">
                    <v-list-item class="pl-0 pt-3">
                      <v-list-item-avatar color="grey darken-3">
                        <v-img
                          class="elevation-6"
                          src="https://randomuser.me/api/portraits/men/1.jpg"
                        ></v-img>
                      </v-list-item-avatar>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-card-title
                      class="pl-2 pt-3 subtitle-1 font-weight-bold"
                    >
                      Top western road trips
                    </v-card-title>
    
                    <v-card-subtitle class="pl-2 pb-0">
                      1,000 miles of wonder
                    </v-card-subtitle>
                    <v-card-subtitle class="pl-2 pt-0">
                      9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                      ago
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card> -->
            </v-skeleton-loader>
          </v-col>
        </v-row>
    </v-card-text>

      </v-card>
    
    </template>
    
    <script>
    import VideoCard from '@/components/VideoCard'
    
    export default {
      data: () => ({
        tab: null,
        loading: true,
        items: ['홈', '콘텐츠', '감상 목록', '커뮤니티'],
        video: {
          url: '/watch/12',
          thumb: 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg',
          title: 'Top western road trips',
          views: '96만 회',
          createdAt: '6 시간 전'
        },
        channel: {
          url: '/channels/12',
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
        },
        
    dummies:[
    //   {
    //   video: {
    //     url: '/watch/12',
    //     thumb: 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg',
    //     title: 'ㅇㅇㅇㅇㅇㅇㅇ',
    //     views: '96만 회',
    //     createdAt: '6 시간 전'
    //   },
    //   channel: {
    //     url: '/channels/12',
    //     avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    //   },
    //   tags: ['webtoon', 'news','recent']
    // },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/brave_thumb.png'),
        title: '용기 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_01.png'),
      },
      tags: ['comics', 'recent']
    },
    {
      video: {
        url: '/watch/01?content=novel',
        thumb: require('@/assets/thumbnails/cnp_thumb.png'),
        title: '죄와 벌 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_02.jpg'),
      },
      tags: ['novel', 'recent']
    },
    {
      video: {
        url: '/watch/01?content=magazine',
        thumb: require('@/assets/thumbnails/bnb_thumb.png'),
        title: 'Bird and Blooms 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_03.png'),
      },
      tags: ['magazine', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_01.jpg'),
        title: '킹덤 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_04.png'),
      },
      tags: ['comics', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_02.jpg'),
        title: '쿵푸판다 4 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_05.png'),
      },
      tags: ['comics', ]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_03.png'),
        title: '귀멸의 칼날 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_06.png'),
      },
      tags: ['comics', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_04.jpg'),
        title: '해리포터 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_07.png'),
      },
      tags: ['novel', ]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_05.jpg'),
        title: '나혼자만 레벨업 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_08.png'),
      },
      tags: ['webtoon', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_06.jpg'),
        title: '원피스 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_09.png'),
      },
      tags: ['comics',]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_07.jpg'),
        title: '타임즈 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_11.png'),
      },
      tags: ['magazine', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_08.jpg'),
        title: '오베라는 남자 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_12.png'),
      },
      tags: ['novel',]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_09.jpg'),
        title: '노인과 바다 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_13.png'),
      },
      tags: ['novel', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_10.jpg'),
        title: '빨강 머리 앤 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_14.png'),
      },
      tags: ['novel',]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_11.jpg'),
        title: '슬램덩크 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_15.png'),
      },
      tags: ['comics', 'recent']
    },
  ],
      }),
      components: {
        VideoCard,
      },
      mounted() {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    }
    </script>
    
    <style>
    .nav-bgcolor {
      /* background: #f9f9f9; */
    }
    
    .card {
      /* background: #f9f9f9 !important; */
    }
    
    .v-tab {
      margin-right: 4em;
    }
    
    #channel-home .v-list-item--link:before {
      background-color: transparent;
    }
    
    .no-vbtnstyle {
         text-transform: unset !important;
         letter-spacing: unset !important;
    }
    </style>
    