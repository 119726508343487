
      <template>

            <v-card flat> 
                <v-container>
                    
                <v-card-title >
                    <p class="my-0" >Grape를 원하는 대로 설정하기</p>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-container >
                        <v-row>
                            <v-col cols="2">
                                사용자 ID
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                  value="vcmzvi89pa4dzs"
                                  readonly
                                  append-icon="mdi-content-copy"
                                  style="width: 300px;"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                채널 ID
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                  value="VDJOJDJ5378vEd"
                                  readonly
                                  append-icon="mdi-content-copy"
                                  style="width: 300px;"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                채널 이전
                            </v-col>
                            <v-col cols="10">
                              <div><a href="#">브랜드 계정으로 채널 이전</a></div>
                              <div>브랜드 계정으로 채널을 이전할 수 있습니다</div>
                            </v-col>
                            <v-col cols="2">
                                채널 삭제
                            </v-col>
                            <v-col cols="10">
                              <a href="#">채널 삭제</a>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-card-text>
            </v-container>

              </v-card>
            </template>      
  
<script>
export default {
    data() {
        return {
            comment:'on',
            switches:[
                {
                    label:'구독',
                    desc:'구독중인 채널 활동에 대한 알람 수신',
                    value:'true',
                 },
                {
                    label:'맞춤 콘텐츠',
                    desc:'감상한 콘텐츠를 바탕으로 내가 좋아할 만한 콘텐츠에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'내 채널의 활동',
                    desc:'내 채널이나 콘텐츠의 댓글 및 기타 활동에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'내 댓글에 달린 답글',
                    desc:'다른 채널에 남긴 댓글 및 게시물의 활동에 대한 알림 수신',
                    value:'true',
                },
                {
                    label:'멘션',
                    desc:'다른 사용자가 내 채널을 언급하면 알림 받기',
                    value:'true',
                },
                {
                    label:'공유된 콘텐츠',
                    desc:'다른 사용자가 자신의 채널에서 내 콘텐츠를 공유하면 알림 수신',
                    value:'true',
                },
                {
                    label:'프로모션 콘텐츠 및 혜택',
                    desc:'프로모션 콘텐츠 및 회원 전용 혜택과 같은 혜택에 대한 알림 수신',
                    value:'true',
                },

            ],
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color: #065fd4;
}

.v-card__title{
    flex-direction: column;
    align-items: flex-start;
}
</style>

<style>

.multipleLineLabel>.v-input__control>.v-input__slot>.v-label{
    flex-direction: column;
    align-items: flex-start;
}
</style>