<!-- 8-D-8 -->
<template>
  <div id="video-details" class="pa-4">
    <v-container>
      <!-- <div class="d-flex justify-space-between mb-5"> -->
      <v-btn text small class="pl-0" @click="$router.go(-1)"
        ><v-icon left>mdi-arrow-left</v-icon> 채널 콘텐츠</v-btn
      >
      <h2 class="my-5">콘텐츠 세부정보</h2>
      <v-row>
        <v-col cols="8">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
              <ValidationProvider
                v-slot="{ errors }"
                name="Title"
              >
                <v-text-field
                  v-model="formData.title"
                  :error-messages="errors"
                  label="제목 (필수 항목)"  
                  placeholder="제목을 입력해 주세요."
                  persistent-placeholder
                  class="mb-3"
                  outlined
                  counter="100"
                  max-length="100"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="Description"
              >
                <v-textarea
                  outlined
                  auto-grow
                  :error-messages="errors"
                  label="설명"
                  placeholder="감상자에게 콘텐츠에 대해 설명해 주세요."
                  persistent-placeholder
                  rows="5"
                  counter="5000"
                  max-length="5000"
                  v-model="formData.description"
                  row-height="20"
                ></v-textarea>
              </ValidationProvider>

              <h3>썸네일</h3>
        <div class="text-subtitle-2 my-2 secondary--text">눈에 띄고 감상자의 관심을 끄는 썸네일을 설정하세요. <a class="text-decoation-none" style="color:#065fd4;"> 자세히 알아보기</a>
          </div>
          
          <v-avatar
                width="200"
                height="125"
                color="grey"
                class="flex-grow-0 flex-shrink-0"

                tile
              >
              <img :src="require('@/assets/thumbnails/thumbnail_06.jpg')" alt="alt" contain>
             </v-avatar>


              
<template>
  
  <v-container
    class="px-0"
    fluid
  >
  <h3>감상자층</h3>
    <v-radio-group v-model="radioGroup">
      <v-radio
        v-for="(rating,i) in ratings"
        :key="i"
        :label="rating.label"
        :value="rating.value"
      ></v-radio>
    </v-radio-group>
  </v-container>
</template>

              <div class="mt-6 d-flex justify-start">
                <v-btn type="reset" depressed>변경사항 실행 취소</v-btn>
                <v-btn type="submit" class="primary ml-3" depressed>저장</v-btn>
              </div>
            </form>
          </ValidationObserver>
        </v-col>
        <v-col cols="4" class="text-center">
          <!-- <v-btn text @click="toggleShow">Upload Thumbnails</v-btn> -->
          <my-upload
            field="img"
            @crop-success="cropSuccess"
            v-model="show"
            :width="400"
            :height="250"
            :params="params"
            :headers="headers"
            img-format="jpg"
            langType="en"
          ></my-upload>
          <v-responsive style="max-width: 100%">
            <div v-if="!imgDataUrl" class="px-12" id="image-placeholder">
              <v-icon>mdi-image-plus</v-icon>
            </div>
            <v-img v-else height="200" :src="require('@/assets/thumbnails/thumbnail_06.jpg')"></v-img>
            
          <v-text-field outlined
          class="mt-5"
            label="콘텐츠 링크"
            :value="`https://grape.com/VD39QD`"
            append-icon="mdi-content-copy"
            readonly
          ></v-text-field>
          <v-text-field outlined
            label="파일 이름"
            :value="`grape_video_1.pdf`"
            readonly
          ></v-text-field>
          <v-select
            :items="['비공개','일부공개','공개']"
            v-model="privacy"
            label="공개상태"
            outlined
          ></v-select>
          
          <v-select
            :items="['없음']"
            value='없음'
            label="제한사항"
            outlined
            disabled
          ></v-select>

          
          <v-text-field outlined
            label="번역"
            :value="translateLanguages"
            disabled
            prepend-inner-icon="mdi-subtitles-outline"
          >
          <template v-slot:append>
            <v-btn icon small @click.stop="tdialog = true"><v-icon>mdi-pencil</v-icon></v-btn>
          <translate-dialog v-model="tdialog"></translate-dialog>

            </template>
        </v-text-field>
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TranslateDialog from '@/components/dialogs/TranslateDialog.vue';
import myUpload from 'vue-image-crop-upload'
export default {
  name: 'Details',
  data() {
    return {
      translateLanguages:[],
      tdialog:false,
      privacy:'비공개',
      ratings:[
        {
          label: '콘텐츠 이용자를 전체 감상가로 정하겠습니다.',
          value: 1
        },
        {
          label: '콘텐츠 이용자를 만13세 이상으로 제한하겠습니다.',
          value: 2
        },
        {
          label: '콘텐츠 이용자를 만19세 이상으로 제한하겠습니다.',
          value: 3
        },
      ],
      // dialog: this.openDialog ? this.openDialog : false,
      valid: false,
      uploaded: false,
      uploading: false,
      interval: {},
      value: 0,
      show: false,
      rules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'Video size should be less than 5 MB!'
      ],
      categories: ['People', 'Technology', 'Fashion'],
      visibilty: ['Public', 'Private'],
      formData: {
        title: '',
        description: '',
        category: '',
        visibilty: ''
      },
      imgDataUrl: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      }
    }
  },
  methods: {
    submit() {
      console.log('submittied')
      this.closeModal()
    },
    toggleShow() {
      this.show = !this.show
    },
    cropSuccess(imgDataUrl, field) {
      console.log('-------- crop success --------')
      console.log(field)
      this.imgDataUrl = imgDataUrl
    }
  },
  components: {
    myUpload,
    TranslateDialog,
  },
  mounted() {}
}
</script>

<style lang="scss">
.card {
  // background: #f9f9f9 !important;
}
</style>
