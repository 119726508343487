<!-- 8-D -->
<template>
    <div id="video" class="pa-4 pl-0">
      <!-- <v-container fluid> -->
      <h2>채널 콘텐츠</h2>
  
      <!-- <v-row> -->
      <v-tabs v-model="tab" id="tab" class="mt-5">
        <v-tab>
          콘텐츠
        </v-tab>
        <v-tab>
          게시물
        </v-tab>
        <v-tab>
          감상 목록
        </v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <template>
            <v-card flat>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="필터"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                no-data-text="콘텐츠가 없습니다."
                :headers="headers"
                :items="videos"
                :search="search"
                :loading="loading"
                loading-text="로딩중"
                show-select
                :footer-props="{
                  'items-per-page-text': '페이지당 행 수',
                  pageText: '{2} 중 {0}-{1}',
                }"
              >
                <template v-slot:top>
                  <v-dialog v-model="dialogDelete" persistent max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline"
                          >Permanently delete this video?</span
                        >
                      </v-card-title>
  
                      <v-card-text>
                        <v-container>
                          <v-card class="card" tile flat>
                            <v-row no-gutters>
                              <v-col cols="3" sm="2" md="5" lg="5">
                                <v-img
                                  class="align-center"
                                  src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                                >
                                </v-img>
                              </v-col>
                              <v-col>
                                <div class="ml-2">
                                  <v-card-title
                                    class="pl-2 subtitle-1 font-weight-bold"
                                    style="line-height: 1"
                                  >
                                    {{ itemToDelete.name }}
                                  </v-card-title>
  
                                  <v-card-subtitle
                                    class="pl-2 pt-2 pb-0"
                                    style="line-height: 1"
                                  >
                                    Published Apr 20, 2020 <br />
                                    {{ itemToDelete.views }} views
                                  </v-card-subtitle>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialogDelete = !dialogDelete"
                          >Cancel</v-btn
                        >
  
                        <v-btn color="blue darken-1" text @click="deleteItem"
                          >Delete Forever</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                
                <template v-slot:item.name="{ item }">
                  <router-link to="/studio/details/11">
                    <v-img
                    :src='item.thumb'
                    max-height="45px"
                    max-width="75px"
                    style="display:inline-block; float:left"
                    ></v-img>
                  </router-link>

                  <p class="my-0">ㅤ{{ item.name }}</p>
                  <p class="mb-0" style="color:gray">ㅤ설명</p>
                </template>
                
                <!-- TODO slot 공개상태 값에 따라 icon 추가 -->
  
              </v-data-table>
            </v-card>
          </template>
        </v-tab-item>
        <v-tab-item>
        </v-tab-item>
      </v-tabs-items>
      <!-- </v-row> -->
      <!-- </v-container> -->
    </div>
  </template>
  
  <script>
  export default {
    data: () => ({
      loading: true,
      dialogDelete: false,
      tab: null,
      search: '',
      headers: [
        {
          text: '콘텐츠ㅤㅤㅤㅤㅤ',
          align: 'start',
          value: 'name'
        },
        { text: '공개상태', value: 'visibility' },
        { text: '제한사항', value: 'restrictions' },
        { text: '날짜', value: 'date', },
        { text: '조회수', value: 'views' },
        { text: '댓글', value: 'comments' },
        { text: '좋아요 (싫어요 대비)', value: 'likes (vs. dislikes)' },
      ],
      videos: [],
      itemToDelete: {}
    }),
    methods: {
      editItem(item) {
        this.$router.push({ name: `Detail`, params: { id: item.id } })
      },
      deleteBtn(item) {
        this.dialogDelete = true
        this.itemToDelete = item
      },
      deleteItem() {
        this.dialogDelete = false
        this.videos = this.videos.filter(
          (video) => this.itemToDelete.id !== video.id
        )
      }
    },
    mounted() {
      setTimeout(() => {
        this.loading = false
        this.videos = [
          {
            'thumb' : require('@/assets/thumbnails/thumbnail_01.jpg'),
            'name': '내 콘텐츠1',
            'visibility': '공개',
            'restrictions': '없음',
            'views': 10,
            'comments': 5,
            'likes (vs. dislikes)': 10,
            'id': 12233,
            'date':'2021.11.27',
          },
          {
            'thumb' : require('@/assets/thumbnails/thumbnail_02.jpg'),
            'name': '내 콘텐츠2',
            'visibility': '공개',
            'restrictions': '없음',
            'views': 13,
            'comments': 15,
            'likes (vs. dislikes)': 20,
            'id': 12234,
            'date':'2021.10.27',
          },
          {
            'thumb' : require('@/assets/thumbnails/thumbnail_03.png'),
            'name': '내 콘텐츠3',
            'visibility': '공개',
            'restrictions': '없음',
            'views': 10,
            'comments': 45,
            'likes (vs. dislikes)': 60,
            'id': 122133,
            'date':'2021.12.27',
          }
        ]
      }, 1000)
    }
  }
  </script>
  
  <style lang="scss">
  // .card,
  // #tab .v-tabs-bar {
  //   background: #f9f9f9 !important;
  // }
  </style>
  