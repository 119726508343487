<!-- 12-A -->
<template>

    <v-card class="transparent" flat>
        <v-card-title>
            <div class="d-flex align-center justify-space-between" style="width: 100%;">
                최신순
    
                <div>
                    <v-btn text color="blue lighten-1">관리</v-btn>
                    <v-btn text>
                        <v-icon>
                            mdi-view-module
                        </v-icon>
                    </v-btn>
                    <v-btn text>
                        <v-icon>
                            mdi-format-list-bulleted
                        </v-icon>
                    </v-btn>
                </div>
            </div>
    
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            v-for="i in 7"
            :key="i"
            class="mx-xs-auto"
          >
            <v-skeleton-loader type="card-avatar" :loading="loading">
                <v-card>
                    <v-card-text>
                        <div class="d-flex align-center justify-space-around" style="width: 100%;">
                            <v-avatar
                                size="100"
                                color="grey"
                                class="flex-grow-0 flex-shrink-0 mx-10"
                            >
                                <v-img :src="avatars[i]" alt="alt"/>
                            </v-avatar>
                            <div class="flex-grow-1" style="max-width: 500px;">
                                <p class="text-subtitle-1">채널이름</p>
                                <p class="text-caption my-1">@channel · 구독자 11명</p>
                                <p class="text-caption my-1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, voluptates? Explicabo aliquam ipsa laborum voluptatem fuga quod aperiam expedita!</p>
                            </div>
                            <template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">

                            <v-btn class="flex-grow-0 flex-shrink-0 mx-10" rounded
                            v-on="on"
                            v-bind="attrs"
                            width="150"
                            >
                                <v-icon>{{items[optionsState[i]].icon}}</v-icon>
                                <span class="mx-1">{{items[optionsState[i]].text}}</span>
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title class="d-flex align-center" @click="optionsState[i]=index">
            
            <v-icon class="mr-1">{{ item.icon }}</v-icon>
            <span class="mx-1">{{ item.text }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
                        </div>
                    </v-card-text>
                </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card>
    
    </template>
    
    <script>
    
    export default {
      data: () => ({
        optionsState: [0, 1, 2, 0, 1, 2, 0, 1, 2, 0, 1, 2],
        items:[
          {
            icon: 'mdi-bell-ring',
            text: '전체'
          },
          {
            icon: 'mdi-bell-outline',
            text: '맞춤설정'
          },
          {
            icon: 'mdi-bell-off-outline',
            text: '없음'
          },
          {
            icon: 'mdi-account-minus',
            text: '구독 취소'
          },
        ],
        tab: null,
        loading: true,
        video: {
          url: '/watch/12',
          thumb: 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg',
          title: 'Top western road trips',
          views: '96만 회',
          createdAt: '6 시간 전'
        },
        channel: {
          url: '/channels/12',
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
        },
    avatars:[
      require('@/assets/avatars/avatar_01.png'),
      require('@/assets/avatars/avatar_02.jpg'),
      require('@/assets/avatars/avatar_03.png'),
      require('@/assets/avatars/avatar_04.png'),
      require('@/assets/avatars/avatar_05.png'),
      require('@/assets/avatars/avatar_06.png'),
      require('@/assets/avatars/avatar_07.png'),
    ],
      }),
      components: {
      },
      mounted() {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    }
    </script>
    
    <style>
    .nav-bgcolor {
      /* background: #f9f9f9; */
    }
    
    .card {
      /* background: #f9f9f9 !important; */
    }
    
    .v-tab {
      margin-right: 4em;
    }
    
    #channel-home .v-list-item--link:before {
      background-color: transparent;
    }
    
    .no-vbtnstyle {
         text-transform: unset !important;
         letter-spacing: unset !important;
    }
    </style>
    