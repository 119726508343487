<template>
    <v-dialog
      :value="dialog"
      @input="dialog = $event"
      max-width="400px"
      scrollable
    >
      <v-card>
        <v-card-title>콘텐츠 신고</v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text style="height: 300px;">
          <v-radio-group
            v-model="dialogm1"
            column
          >
          <!-- TODO: fill out rest -->
            <v-radio
              v-for="(item,index) in list"
              :key="index"
              value="sexualContent"
            >
            <template v-slot:label>
                <div>
                    <span class="px-2">
                    {{item}}
                </span>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    >
                    mdi-help-circle-outline
                    </v-icon>
                </template>
                <span></span>
                </v-tooltip>
                </div>
            </template>
            </v-radio>
<!-- 
            <v-radio
              label="Bahrain"
              value="bahrain"
            ></v-radio>
            <v-radio
              label="Bangladesh"
              value="bangladesh"
            ></v-radio>
            <v-radio
              label="Barbados"
              value="barbados"
            ></v-radio>
            <v-radio
              label="Belarus"
              value="belarus"
            ></v-radio>
            <v-radio
              label="Belgium"
              value="belgium"
            ></v-radio>
            <v-radio
              label="Belize"
              value="belize"
            ></v-radio>
            <v-radio
              label="Benin"
              value="benin"
            ></v-radio>
            <v-radio
              label="Bhutan"
              value="bhutan"
            ></v-radio>
            <v-radio
              label="Bolivia"
              value="bolivia"
            ></v-radio>
            <v-radio
              label="Bosnia and Herzegovina"
              value="bosnia"
            ></v-radio>
            <v-radio
              label="Botswana"
              value="botswana"
            ></v-radio>
            <v-radio
              label="Brazil"
              value="brazil"
            ></v-radio>
            <v-radio
              label="Brunei"
              value="brunei"
            ></v-radio>
            <v-radio
              label="Bulgaria"
              value="bulgaria"
            ></v-radio>
            <v-radio
              label="Burkina Faso"
              value="burkina"
            ></v-radio>
            <v-radio
              label="Burma"
              value="burma"
            ></v-radio>
            <v-radio
              label="Burundi"
              value="burundi"
            ></v-radio> -->
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            다음
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogm1: '',
        list:[
          "성적인 콘텐츠",
          "폭력적 또는 혐오스러운 콘텐츠",
          "증오 또는 악의적인 콘텐츠",
          "괴롭힘 또는 폭력",
          "위급한 신체적 손상",
          "유해하거나 위험한 행위",
          "잘못된 정보",
          "아동 학대",
          "테러 조장",
          "스팸 또는 혼동을 야기하는 콘텐츠",
          "법적 문제",
          "자막 문제",
        ]
      };
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
  </script>