<!-- 8-F -->
<template>
    <v-container fluid >
        <v-row>
            <v-col cols=12>
                <div class="d-flex" >
                <v-card
                elevation=0
                dark
                width="350px"
                class="flex-shrink-0 rounded-xl mx-3"
                >
                    <div>
                        <v-img
                        :src="require('@/assets/thumbnails/thumbnail_06.jpg')"
                        class="grey lighten-2 ma-3"
                        contain
                        >
                        </v-img>
                    </div>
                        
                    <v-card-title>
                        나중에 볼 콘텐츠
                    </v-card-title>
                    <v-card-text>
                        나무
                        <p class="text-caption ">동영상 324개 조회수 없음 최종 업데이트 :2023.01.01</p>

                        <v-btn
                        fab
                        x-small
                        elevation=0
                        >
                        <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </v-card-text>

                    <v-card-actions>
                        
                    </v-card-actions>
                </v-card>

                <div width="100%" class="flex-shrink-1 flex-grow-1" >
                    <v-alert
                    dense
                    dismissible
                    text
                    > 
                    사용할 수 없는 콘텐츠는 숨겨졌습니다.
                </v-alert>

                <div class="d-flex align-center text-body-2"><template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
        small
        class="pl-1 mb-3"
        v-bind="attrs"
          v-on="on"
        >
                    <v-icon small class="ma-2">mdi-sort-variant</v-icon>
                    정렬
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
                </div>
                
                    <div v-for="i in 10" :key="i" class="mb-5">
                        <v-skeleton-loader
                        class="mx-auto"
                        type="list-item-avatar-three-line"
                        :loading="loading"
                        tile
                        large
                        >
                        <v-card class="card py-1" tile flat>
                            <div class="d-flex align-center">

                                <v-icon small class="ma-2">mdi-drag-horizontal-variant</v-icon>
                                <v-responsive max-height="80px" max-width="120px"
                                height="80px" width="120px"
                                class="flex-shrink-0">
                                <v-img
                                class="align-center"
                                :src="thumbs[i]"
                                :aspect-ratio="16/9"
                                height="80px"
                                >
                                </v-img>
                                </v-responsive>
                                
                                <div class="ml-2">
                                <v-card-title
                                    class="pl-2 pt-0 subtitle-1 font-weight-bold"
                                    style="line-height: 1"
                                >
                                콘텐츠 이름 콘텐츠 이름 콘텐츠 이름 
                                </v-card-title>

                                <v-card-subtitle
                                    class="pl-2 pt-2 pb-0"
                                    style="line-height: 1"
                                >
                                    채널이름<br />
                                    조회수 99회<v-icon>mdi-circle-small</v-icon> 3년 전
                                </v-card-subtitle>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn icon small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </div>
                        </v-card>
                        </v-skeleton-loader>
                    </div>
                
                <div>
                    <v-skeleton-loader
                    class="mx-auto"
                    type="list-item-avatar-three-line"
                    :loading="loading"
                    tile
                    large
                    >
                    <v-card class="card" tile flat>
                        <div class="d-flex align-center">

                            <v-icon small class="ma-2">mdi-drag-horizontal-variant</v-icon>
                            <v-responsive max-height="80px" max-width="120px" class="flex-shrink-0">
                            <v-img
                            class="align-center"
                            src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                            height="80px"
                            contain
                            >
                            </v-img>
                            </v-responsive>
                            
                            <div class="ml-2">
                            <v-card-title
                                class="pl-2 pt-0 subtitle-1 font-weight-bold"
                                style="line-height: 1"
                            >
                                Top western road trips
                            </v-card-title>

                            <v-card-subtitle
                                class="pl-2 pt-2 pb-0"
                                style="line-height: 1"
                            >
                                Tech Reagan<br />
                                9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                                ago
                            </v-card-subtitle>
                            </div>
                        </div>
                    </v-card>
                    </v-skeleton-loader>
                </div>
                    </div>

                </div>
            </v-col>

        </v-row>
        
    </v-container>

</template>

<script>
export default {
    data: () => ({
        loading: false,
      items: [
        { title: '추가된 날짜(최신순)' },
        { title: '추가된 날짜(오래된순)' },
        { title: '인기순' },
        { title: '게시된 날짜(최신순)' },
        { title: '게시된 날짜(오래된순)' },
      ],
      thumbs:[
        require('@/assets/thumbnails/thumbnail_01.jpg'),
        require('@/assets/thumbnails/thumbnail_02.jpg'),
        require('@/assets/thumbnails/thumbnail_03.png'),
        require('@/assets/thumbnails/thumbnail_04.jpg'),
        require('@/assets/thumbnails/thumbnail_05.jpg'),
        require('@/assets/thumbnails/thumbnail_06.jpg'),
        require('@/assets/thumbnails/thumbnail_07.jpg'),
        require('@/assets/thumbnails/thumbnail_08.jpg'),
        require('@/assets/thumbnails/thumbnail_09.jpg'),
        require('@/assets/thumbnails/thumbnail_10.jpg'),
        require('@/assets/thumbnails/thumbnail_11.jpg'),
        require('@/assets/thumbnails/thumbnail_12.jpg'),
      ]
    }),
}
</script>