<!-- 8-C -->
<template>
        <v-container fluid>
              <v-card class="transparent" flat>
                <v-card-title class='text-h4'>감상 목록</v-card-title>
                <v-card-text>
                  <v-select
                    :items="['가나다순','최신순']"
                    label=""
                    style="width: 100px"
                    value='가나다순'
                  ></v-select>
                </v-card-text>
                
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="(d,i) in 5"
          :key="i"
          class="mx-xs-auto"
        >
          <v-skeleton-loader type="card-avatar" :loading="loading" style="height: 100%;">
            <video-card
              :card="{ maxWidth: 350 }"
              :video="video"
              :channel="channel"
            ></video-card>
          </v-skeleton-loader>
        </v-col>
        
      </v-row>
              </v-card>
        </v-container>
</template>


<script>
import VideoCard from '@/components/VideoCard'
export default {
  data: () => ({    tab: null,
    loading: true,
    video: {
      url: '/watch/12',
      thumb: require('@/assets/thumbnails/thumbnail_07.jpg'),
      title: '나중에 볼 콘텐츠',
      views: '비공개',
      createdAt: '재생목록'
    },
    channel: {
      url: '/channels/12',
      avatar: require('@/assets/grape_logo_only.png')
    },
    playlists:[],
  }),
  components: {
    VideoCard
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }
}
</script>

<style>
.nav-bgcolor {
  /* background: #f9f9f9; */
}

.card {
  /* background: #f9f9f9 !important; */
}

.v-tab {
  margin-right: 4em;
}

#channel-home .v-list-item--link:before {
  background-color: transparent;
}

.no-vbtnstyle {
     text-transform: unset !important;
     letter-spacing: unset !important;
}
</style>
